import React from "react";

const AnnotationReview = ({ section_with_image = {}, strongColor }) => {
  const strongClass = () => {
    return `strong_color_${strongColor}`;
  };
  return (
    <div className="flex flex-col justify-center lg:justify-between lg:w-full items-center lg:mx-auto gap-3.5 sm:gap-12 lg:gap-12 lg:flex-row w-full">
      <div className="w-full lg:w-auto">
        <img
          src={section_with_image?.primary?.section_image?.url}
          width={544}
          height={446}
          className="w-full lg:max-w-[544px] md:max-h-[446px]"
          alt="img"
        />
      </div>
      <div className="flex flex-col w-full gap-6 lg:w-auto">
        <div
          dangerouslySetInnerHTML={{
            __html: section_with_image?.primary?.section_heading?.html,
          }}
          className={`text-2.5xl font-bold flex-wrap lg:max-w-[544px] tracking-[-1.5px] font-manrope leading-9 text-purple-1100 dark:text-white ${strongClass()}`}
        ></div>
        <p className="flex-wrap text-base font-normal lg:max-w-[544px] text-gray-600 font-inter dark:text-gray-2200 tracking-tighter_3 ">
          {section_with_image?.primary?.section_description?.text}
        </p>
        <div className="flex flex-col sm:justify-between sm:flex-row">
          <div className="flex flex-col gap-1">
            <p className="text-lg font-semibold text-purple-1100 dark:text-gray-2800 font-manrope tracking-tighter_1">
              {section_with_image?.primary?.footer_label?.text}
            </p>
            <span className="text-base font-normal text-gray-600 dark:text-gray-2200 font-inter tracking-tighter_3">
              {section_with_image?.primary?.footer_sub_description?.text}
            </span>
          </div>

          <div className="flex items-center sm:justify-center">
            {section_with_image?.primary?.footer_image && (
              <img
                src={section_with_image?.primary?.footer_image?.url}
                width={153}
                height={22}
                alt="testimonial_logo_image"
                className=" sm:w-[153px] w-[77px] h-[18px] sm:h-[22px] mt-4 sm:mt-0"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnotationReview;
