import React from "react";
import CustomButton from "../CustomButton";

const SectionHeadingWrapper = ({
  sectionData,
  children,
  Icon,
  iconTextStyle,
  strongColor,
  buttonOnClick,
  heading_style,
  darkMode,
  buttonStyleLight,
  buttonStyleDark,
}) => {
  const strongClass = () => {
    return `strong_color_${strongColor}`;
  };
  return (
    <>
      <div className="relative z-10 pt-10 pb-10 sm:pt-20 sm:pb-12 lg:pb-16 ">
        <div className="flex items-center w-full max-w-lg gap-2">
          {Icon &&
          <img
            src={Icon}
            alt={sectionData?.icon?.alt || "img"}
            className="h-auto w-7"
            loading="lazy"
          />
          }
          <h2
            className={`text-xl sm:text-2xl font-semibold tracking-tighter_3 ${
              strongColor == "yellow" ? "text-gray-2500" : "text-gray-2700"
            } dark:text-white leading-8 font-manrope ${iconTextStyle}`}
          >
            {sectionData?.section_label?.text || sectionData?.label?.text}
          </h2>
        </div>
        <div className="flex flex-col gap-4 mb-7 md:mb-10 mt-9">
          {sectionData?.section_heading?.html ? (
            <div
              dangerouslySetInnerHTML={{
                __html: sectionData?.section_heading?.html,
              }}
              className={`text-2.5xl sm:text-4xl lg:text-4.5xl sm:leading-10 lg:leading-[48px] !font-bold tracking-tight_1 leading-8 text-purple-1100 dark:text-white font-manrope ${strongClass()} ${heading_style}`}
            />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: sectionData?.heading?.html,
              }}
              className={`text-2.5xl sm:text-4xl lg:text-4.5xl sm:leading-10 lg:leading-[48px] !font-bold tracking-tighter_3 leading-8 text-purple-1100 dark:text-white font-manrope ${strongClass()} ${heading_style}`}
            />
          )}

          <p className="text-base sm:text-lg font-normal text-gray-600 dark:text-gray-2900 leading-7 tracking-tight_1 max-w-[736px]">
            {sectionData?.section_description?.text}
          </p>
        </div>
        {sectionData?.cta_text?.text ? (
          <CustomButton
            text={sectionData?.cta_text?.text}
            link={sectionData?.section_cta_link?.url}
            onClick={buttonOnClick ? buttonOnClick : sectionData?.onClick}
            buttonClass={`${
              darkMode ? buttonStyleDark : buttonStyleLight
            } bg-white text-base text-white`}
          />
        ) : (
          <CustomButton
            text={sectionData?.section_cta_text?.text}
            link={sectionData?.cta_link?.url}
            onClick={buttonOnClick ? buttonOnClick : sectionData?.onClick}
            buttonClass={`${
              darkMode ? buttonStyleDark : buttonStyleLight
            } bg-white text-base text-white`}
          />
        )}
      </div>
      <div className="">{children}</div>
    </>
  );
};

export default SectionHeadingWrapper;
