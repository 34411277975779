import { graphql } from "gatsby";
import React, { useState } from "react";
import {
  HomeMobileFooter,
  ItkHeroVector,
  ItkHeroVectorTab,
  ITKMobileVector,
} from "../assets/backgrounds";
import vector from "../assets/backgrounds/itk-vector.png";
import desktopFooter from "../assets/backgrounds/pricingFooter.png";
import DarkmodeIcon from "../assets/icons/AnnotationToolIcons/DarkmodeIcon";
import LightmodeIcon from "../assets/icons/AnnotationToolIcons/LightmodeIcon";
import HeroPoster from "../assets/posters/industry-logistics.png";
import DemoModal from "../components/DemoModal";
import AnnotationTools from "../components/ITK-Comp-LP/AnnotationTools";
import AnnotationTypes from "../components/ITK-Comp-LP/AnnotationTypes";
import G2reviews from "../components/ITK-Comp-LP/G2reviews";
import SectionHeadingWrapper from "../components/ITK-Comp-LP/SectionHeadingWrapper";
import TablebodyMobile from "../components/ITK-Comp-LP/TablebodyMobile";
import TableBodyRowSection from "../components/ITK-Comp-LP/TableBodyRowSection";
import VerticalCardsSection from "../components/ITK-Comp-LP/VerticalCardsSection";
import Layout from "../components/layout";
import NewHomeLeadFormWithAPIForm from "../components/NewHomeLeadFormWithAPI";
import CTASection from "../components/pricing_page/CTASection";
import VideoComponent from "../components/VideoComponent";
import "../styles/page/itk-comp-lp.scss";
import { pickSlice } from "../utilities/helpers";
import AnnotationReview from "../components/ITK-Comp-LP/AnnotationReview";
import LogoMarquee from "../components/LogoMarquee";
import SEO from "../components/seo";

const ActiveIcon = {
  icon: {
    url: "https://encord.cdn.prismic.io/encord/fe00a610-84b5-4bed-987a-0fe349e61c74_productIcons.svg?fit=max",
    alt: "Active",
    text: "Active",
  },
};
const AnnotateIcon = {
  icon: {
    url: "https://encord.cdn.prismic.io/encord/22e23242-2246-4e86-92a8-be555643865d_productIcons+%281%29.svg?fit=max",
    alt: "Annotate",
    text: "Annotate",
  },
};
const DataSecurity = {
  icon: {
    url: "https://encord.cdn.prismic.io/encord/Zmaokpm069VX1lTI_data%26security.svg",
    alt: "_data & Security",
    text: "_data & Security",
  },
};

const CompetitiorsLP = ({ location, data, ...rest }) => {
  const pageUID= rest?.pageContext?.uid
  const _data = data?.prismicCompetitorLandingPageV3?.data;
  const logosLight = data?.allPrismicTrustedBrandsLogos?.nodes?.filter(
    (tl) => tl?.uid === "itk-comp-page-logos-light"
  );
  const logosDark = data?.allPrismicTrustedBrandsLogos?.nodes?.filter(
    (tl) => tl?.uid === "itk-comp-page-logos-dark"
  );
  const [encord_types] = pickSlice(_data, "encord_other_features");
  const page_section = pickSlice(_data, "page_section_");
  const [annotateSection] = page_section.filter(
    (ps) => ps?.primary?.type === "Annotate"
  );
  const [curiousSection] = page_section.filter(
    (ps) => ps?.primary?.type === "Curious"
  );
  const [activeSection] = page_section.filter(
    (ps) => ps?.primary?.type === "Active"
  );
  const [annotation_Tools] = pickSlice(_data, "annotation_tools_section");
  const [section_with_image] = pickSlice(_data, "section_with_image");
  const [annotation_types] = pickSlice(_data, "annotation_types");
  const [contact_sales_quotes] = pickSlice(_data, "contact_sales_quotes");
  const [feature_table] = pickSlice(_data, "feature_table");
  const [isDarkMode, setIsDarkmode] = useState(false);

  const toggleModes = () => {
    setIsDarkmode(!isDarkMode);
  };
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  const strongClass = () => {
    return `strong_bold_underline`;
  };
  return (
    <Layout
      location={location}
      dataMode={isDarkMode}
      theme={isDarkMode ? "purpleWithoutOpacity" : "default"}
    >
      <main className={`relative overflow-hidden bg-white dark:bg-purple-1100`}>
        <div className="relative">
          <div className="absolute top-10">
            <ItkHeroVector />
          </div>
          <article className="px-4 pt-16 mx-auto max-w-7xl md:pt-40 xl:px-0 sm:px-6">
            <section className="relative">
              <div
                className={`fixed bottom-[135px]  sm:bottom-[60px] 2xl:right-[14%] xl:right-[7%] right-[0.8%] z-20 xl:w-20 xl:h-20 sm:w-14 sm:h-14 w-10 h-10 shadow-lg justify-center items-center flex transition-all delay-300 ease-in-out rounded-full cursor-pointer bg-white/90`}
                onClick={toggleModes}
              >
                {isDarkMode ? (
                  <button className="transition-all ease-in-out delay-300">
                    <DarkmodeIcon />
                  </button>
                ) : (
                  <button className="transition-all ease-in-out delay-300">
                    <LightmodeIcon />
                  </button>
                )}
              </div>
              <section className="relative flex flex-col max-w-6xl gap-6 px-4 mx-auto mb-10 sm:mb-8 lg:mt-0 mt-14 lg:px-0 sm:px-12 ">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _data?.page_heading?.html,
                  }}
                  className="text-3.25xl sm:text-4.5xl lg:text-5.5xl leading-10 lg:leading-[68px] -tracking-[3px] font-bold font-manrope text-center text-gray-2700 dark:text-white sm:px-0 px-7"
                />

                <p className="text-base text-center text-gray-600 tracking-tighter_1 sm:text-lg lg:text-xl sm:px-12 lg:px-24 dark:text-gray-2200">
                  {_data?.page_description?.text}
                </p>
                <section className="flex justify-center">
                  <NewHomeLeadFormWithAPIForm
                    location={location}
                    btnText={_data?.cta_button_text?.text}
                    isCustomBtnBg={true}
                    formId="be5df5b6-8694-499a-bde4-b2613bb4bd63"
                    btnColor={`${
                      isDarkMode ? "itk-btn_dark" : "itk-btn"
                    } border-0 px-5 h-[50px]`}
                    inputStyle="dark:bg-[#ffffff1a]"
                  />
                </section>
                <div
                  dangerouslySetInnerHTML={{
                    __html: _data?.sub_heading?.html,
                  }}
                  className={`text-sm ${strongClass()} italic text-center strong_bold_underline font-inter tracking-tighter_2 text-purple-1500 dark:text-purple-1900`}
                />
              </section>

              <section className="lg:max-h-[622px]  my-16 lg:min-h-[510px] curiousSectionShadow mx-auto max-w-[1152px] lg:rounded-3xl sm:rounded-xl rounded-lg relative">
                {_data?.hero_video?.url ? (
                  <VideoComponent
                    poster={HeroPoster}
                    loop
                    autoPlay
                    muted
                    className="mx-auto xl:!max-w-[1152px] lg:rounded-3xl sm:rounded-xl rounded-lg"
                  >
                    <source src={_data?.hero_video?.url} type="video/mp4" />
                  </VideoComponent>
                ) : (
                  <>
                    {data?.hero_section_image?.url ? (
                      <img
                        src={data?.hero_section_image?.url}
                        loading="eager"
                        width={0}
                        height={0}
                        alt="stats"
                        className="max-w-4xl mx-auto w-full max-h-[600px] lg:rounded-3xl sm:rounded-xl rounded-lg"
                      />
                    ) : (
                      <section className="mt-20 2xl:mt-28 w-full h-[512px] rounded-xl shadow-card" />
                    )}
                  </>
                )}
              </section>

              {pageUID === "scale-ai-vs-encord" && (
                <>
                  <article className="relative hidden px-4 lg:block max-w-7xl sm:px-12 lg:px-11 xl:px-16 xl:mx-auto">
                    <SectionHeadingWrapper
                      sectionData={feature_table?.primary}
                      strongColor="purple"
                      buttonOnClick={openModal}
                      heading_style="w-[340px] sm:w-full"
                      darkMode={isDarkMode}
                      buttonStyleLight="itk-btn"
                      buttonStyleDark="itk-btn_dark"
                    >
                      <TableBodyRowSection
                        tableBodyRowData={feature_table}
                        openFeature={true}
                        activePlan={1}
                        tabletLength={10}
                        mobileLength={3}
                        desktopLength={10}
                        smallDesktoplength={10}
                        darkMode={isDarkMode}
                      />
                    </SectionHeadingWrapper>
                  </article>
                  <article className="relative block px-4 lg:hidden max-w-7xl sm:px-12">
                    <SectionHeadingWrapper
                      sectionData={feature_table?.primary}
                      strongColor="purple"
                      buttonOnClick={openModal}
                      heading_style="heading_width_style"
                      darkMode={isDarkMode}
                      buttonStyleLight="itk-btn hover:bg-[#5E24FF]"
                      buttonStyleDark="itk-btn_dark"
                    >
                      <TablebodyMobile
                        tableBodyRowData={feature_table}
                        dark_light_bg={
                          isDarkMode ? "table_dark_bg" : "table_light_bg"
                        }
                        darkMode={isDarkMode}
                      />
                    </SectionHeadingWrapper>
                  </article>
                </>
              )}
              <article className="flex gap-3 mt-10 mb-16 overflow-auto md:mt-16 lg:mt-28 lg:gap-6 HideScrollBar lg:justify-center lg:w-auto ">
                {contact_sales_quotes?.items?.map((value, index) => (
                  <G2reviews
                    key={index}
                    data={value}
                    CardClass={`${
                      index === 0 ? "py-6 gap-0 bg-white" : "py-11"
                    }`}
                    main_Head_class={`${
                      index === 0
                        ? "!pt-8 font-medium tracking-widest_1 uppercase leading-6 text-xs sm:text-base"
                        : "pt-0"
                    }`}
                    main_Heading={value.MainHeading}
                    heading={value.heading}
                    heading_class={`${
                      index == 0
                        ? " mt-3.5 sm:leading-9 sm:leading-10 text-2.5xl lg:text-[36px] !font-bold"
                        : "-tracking-[1.2px]"
                    }`}
                    sub_heading={value.sub_heading}
                    sub_heading_class={`${
                      index == 0
                        ? "text-gray-1200 dark:!text-gray-1600 dark:opacity-60 mt-6 !min-h-[56px] tracking-tighter_3 lg:max-w-[296px] text-lg"
                        : "tracking-tighter_3 sm:text-base mt-6 text-gray-600 dark:text-gray-3000"
                    }`}
                    index={index}
                    LogoDivClass={`${index == 0 ? "mt-5 " : "mt-6"}`}
                  />
                ))}
              </article>

              {(logosDark[0]?.data?.body[0] ||
                logosLight[0]?.data?.body[0]) && (
                <section className="mx-auto branding_loop_width max-h-[125px]">
                  <p className="text-center uppercase text-xs sm:text-base font-medium text-gray-600 dark:text-gray-2200 sm:tracking-[0.1em] tracking-[0.3px]">
                    {isDarkMode
                      ? logosDark[0]?.data?.body[0]?.primary?.label?.text
                      : logosLight[0]?.data?.body[0]?.primary?.label?.text}
                  </p>
                  <LogoMarquee
                    logoArray={
                      isDarkMode
                        ? logosDark[0]?.data?.body[0]
                        : logosLight[0]?.data?.body[0]
                    }
                    logoBackground=""
                    logo_bg
                  />
                </section>
              )}
            </section>
          </article>

          <div className="relative">
            <div className="absolute right-0 z-0 hidden w-screen -top-3 lg:block">
              <img
                src={vector}
                alt="annotate vector"
                loading="eager"
                className="w-full h-auto opacity-60"
              />
            </div>
            <div className="absolute hidden top-10 sm:block lg:hidden">
              <ItkHeroVectorTab />
            </div>
            <div className="absolute top-16 sm:hidden">
              <ITKMobileVector />
            </div>
            <article className="relative px-4 max-w-7xl sm:px-12 lg:px-11 xl:px-16 xl:mx-auto">
              <SectionHeadingWrapper
                Icon={AnnotateIcon?.icon?.url}
                sectionData={annotateSection?.primary}
                strongColor="yellow"
                buttonOnClick={openModal}
                heading_style="w-[300px] sm:w-full"
                darkMode={isDarkMode}
                buttonStyleLight="itk-btn hover:bg-[#5E24FF]"
                buttonStyleDark="itk-btn_dark"
              >
                <AnnotationTypes
                  annotationTypes={annotation_types}
                  backGroundStyle={
                    "bg-white dark:bg-[#ffffff0d] border-gray-1600 dark:border-[#f0f0f033]"
                  }
                  darkMode={isDarkMode}
                />

                <article className="flex justify-start gap-6 my-10 sm:my-16 lg:justify-center lg:w-auto">
                  <AnnotationTools
                    data={annotation_Tools}
                    descriptionClass={`min-h-[48px]`}
                    outlineColor={`text-purple-1500 dark:text-[#FFD643]`}
                  />
                </article>
                <article className="flex flex-col items-center justify-center gap-6 mx-auto my-10 sm:my-16 lg:flex-row">
                  {annotateSection?.items.map((data, index) => (
                    <VerticalCardsSection
                      key={index}
                      darkMode={isDarkMode}
                      data={data}
                      descriptionClass={`min-h-[48px]`}
                      outlineColor={`text-purple-1500 dark:text-[#FFD643]`}
                    />
                  ))}
                </article>
                <article className="flex justify-center gap-6 my-10 sm:my-16 lg:justify-start">
                  <AnnotationReview
                    section_with_image={section_with_image}
                    strongColor={"yellow"}
                  />
                </article>
              </SectionHeadingWrapper>
            </article>
          </div>
          <div className="relative">
            <div className="absolute right-0 z-0 hidden w-screen top-7 lg:block">
              <img
                src={vector}
                alt="anotate vector"
                loading="eager"
                className="w-full h-auto opacity-60"
              />
            </div>
            <div className="absolute hidden top-10 sm:block lg:hidden">
              <ItkHeroVectorTab />
            </div>
            <div className="absolute top-16 sm:hidden">
              <ITKMobileVector />
            </div>
            <article className="relative px-4 max-w-7xl sm:px-12 lg:px-11 xl:px-16 xl:mx-auto">
              <SectionHeadingWrapper
                Icon={ActiveIcon?.icon?.url}
                sectionData={activeSection?.primary}
                strongColor="green"
                buttonOnClick={openModal}
                heading_style="w-[340px] sm:w-full"
                darkMode={isDarkMode}
                buttonStyleLight="itk-btn hover:bg-[#5E24FF]"
                buttonStyleDark="itk-btn_dark"
              >
                <article className="flex flex-col items-center justify-center gap-6 mx-auto lg:flex-row ">
                  {activeSection?.items.map((data, index) => (
                    <VerticalCardsSection
                      data={data}
                      darkMode={isDarkMode}
                      key={index}
                      descriptionClass={`min-h-[96px]`}
                      outlineColor={`text-purple-1500 dark:text-[#73FF5C]`}
                    />
                  ))}
                </article>
              </SectionHeadingWrapper>
            </article>
          </div>

          {pageUID !== "scale-ai-vs-encord" && (
            <>
              <article className="relative hidden px-4 lg:block max-w-7xl sm:px-12 lg:px-11 xl:px-16 xl:mx-auto">
                <SectionHeadingWrapper
                  sectionData={feature_table?.primary}
                  strongColor="purple"
                  buttonOnClick={openModal}
                  heading_style="w-[340px] sm:w-full"
                  darkMode={isDarkMode}
                  buttonStyleLight="itk-btn"
                  buttonStyleDark="itk-btn_dark"
                >
                  <TableBodyRowSection
                    tableBodyRowData={feature_table}
                    openFeature={true}
                    activePlan={1}
                    tabletLength={10}
                    mobileLength={3}
                    desktopLength={10}
                    smallDesktoplength={10}
                    darkMode={isDarkMode}
                  />
                </SectionHeadingWrapper>
              </article>
              <article className="relative block px-4 lg:hidden max-w-7xl sm:px-12">
                <SectionHeadingWrapper
                  sectionData={feature_table?.primary}
                  strongColor="purple"
                  buttonOnClick={openModal}
                  heading_style="heading_width_style"
                  darkMode={isDarkMode}
                  buttonStyleLight="itk-btn hover:bg-[#5E24FF]"
                  buttonStyleDark="itk-btn_dark"
                >
                  <TablebodyMobile
                    tableBodyRowData={feature_table}
                    dark_light_bg={
                      isDarkMode ? "table_dark_bg" : "table_light_bg"
                    }
                    darkMode={isDarkMode}
                  />
                </SectionHeadingWrapper>
              </article>
            </>
          )}

          <article className="relative mx-4 sm:mx-12 max-w-7xl xl:px-16 xl:mx-auto">
            <SectionHeadingWrapper
              sectionData={curiousSection?.primary}
              strongColor="trygreen"
              buttonOnClick={openModal}
              heading_style="w-[170px] sm:w-full"
              darkMode={isDarkMode}
              buttonStyleLight="itk-btn hover:bg-[#5E24FF]"
              buttonStyleDark="itk-btn_dark"
            >
              <section className="w-full h-full mx-auto mb-16 rounded-lg lg:rounded-3xl sm:rounded-xl">
                <div className="h-full pt-[60.25%] relative">
                  <iframe
                    src="https://demo.arcade.software/9kgCKVJIyAVaqnJmwn8b?embed"
                    title="app.encord.com"
                    frameborder="0"
                    className="absolute top-0 left-0 w-full h-full"
                    loading="lazy"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                  ></iframe>
                </div>
              </section>
            </SectionHeadingWrapper>
          </article>
          <article className="security_section_style bg-[#1D0642]">
            <section className="relative mx-4 sm:mx-12 max-w-7xl sm:px-0 xl:px-16 xl:mx-auto">
              <SectionHeadingWrapper
                Icon={DataSecurity?.icon?.url}
                sectionData={encord_types?.primary}
                strongColor="securityPurple"
                iconTextStyle="!text-[#ffffffb3]"
                buttonOnClick={openModal}
                heading_style="w-[266px] sm:w-full"
                darkMode={isDarkMode}
                buttonStyleLight="bg-[#ffffff] !text-[#3F2193]"
                buttonStyleDark="itk-btn_dark"
              >
                <section className="grid grid-cols-1 lg:grid-cols-3 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-11 gap-y-12 sm:pb-20 lg:pb-0">
                  {encord_types?.items?.map(
                    (
                      {
                        card_label,
                        card_heading,
                        card_description,
                        card_image,
                      },
                      index
                    ) => {
                      return (
                        <div
                          key={index}
                          className={`flex flex-col text-white lg:gap-y-7 ${
                            index == 0
                              ? "sm:col-span-2 lg:col-span-1 gap-y-7"
                              : "gap-y-3.5"
                          }`}
                        >
                          <div
                            className={`lg:py-0 flex justify-center ${
                              index != 0 && "sm:py-0 py-24"
                            } ${index === 2 && "sm:py-0 py-7"} `}
                            active_features
                          >
                            <img
                              src={card_image?.url}
                              loading="lazy"
                              alt={card_image?.alt || "img"}
                              className={`custom-gatsby sm:w-auto w-auto  lg:min-h-[382px] ${
                                index == 0
                                  ? "lg:w-auto sm:min-h-max"
                                  : "sm:min-h-[348px] lg:mx-auto mx-12"
                              } `}
                            />
                          </div>
                          <div>
                            <h3 className="mb-4 text-xs font-medium uppercase opacity-50 font-inter sm:text-base tracking-widest_1">
                              {card_label?.text}
                            </h3>
                            <h4 className="sm:text-2.5xl text-2xl font-manrope font-semibold mb-5 lg:h-18 !tracking-tighter_3 pr-4 sm:pr-0">
                              {card_heading?.text}
                            </h4>
                            <p
                              className={`text-sm sm:text-base font-inter text-gray-2200 ${
                                index === 2 && "lg:max-w-[362px]"
                              }`}
                            >
                              {card_description?.text}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </section>
              </SectionHeadingWrapper>
            </section>
          </article>
          <article className="relative overflow-hidden bg-purple-1100">
            <div className="absolute left-0 z-0 hidden -bottom-11 lg:block">
              <img
                src={desktopFooter}
                alt="anotate vector"
                loading="eager"
                className="w-full h-auto opacity-40"
              />
            </div>
            <div className="absolute hidden top-10 sm:block lg:hidden">
              <ItkHeroVectorTab />
            </div>
            <div className="absolute bottom-2 sm:hidden">
              <HomeMobileFooter />
            </div>
            <article className="relative mx-5 py-36 max-w-7xl xl:px-16 xl:mx-auto sm:mx-6 cta_style">
              <CTASection
                headingStyle="!font-manrope"
                subHeadingStyle="!text-gray-2200"
                buttonStyle="!text-lg"
                heading={_data?.cta_heading?.text}
                description={_data?.cta_description?.text}
                location={location}
                openModal={openModal}
              />
            </article>
          </article>
        </div>
        <DemoModal
          isOpen={isOpen}
          closeModal={closeModal}
          location={location}
        />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ($uid: String!) {
    prismicCompetitorLandingPageV3(uid: { eq: $uid }) {
      data {
        sub_heading {
          text
          html
        }
        page_secondary_description {
          html
          text
        }
        page_heading {
          html
          text
        }
        meta_heading {
          text
        }
        page_description {
          text
        }
        meta_description {
          text
        }
        hero_video {
          url
        }
        hero_image {
          dimensions {
            height
            width
          }
          url
          alt
        }
        cta_heading {
          text
          html
        }
        cta_description {
          text
        }
        cta_button_text {
          text
        }
        body {
          ... on PrismicCompetitorLandingPageV3DataBodyAnnotationTypes {
            id
            slice_type
            items {
              coming_soon
              label {
                text
              }
              label_icon {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
              label_link {
                url
              }
              label_slide_image {
                url
              }
              label_slide_video {
                url
              }
            }
            primary {
              section_description {
                text
              }
              section_heading {
                text
                html
              }
              section_label {
                text
              }
            }
          }
          ... on PrismicCompetitorLandingPageV3DataBodyTestimonial {
            id
            slice_type
            items {
              avatar {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              comment {
                html
                text
              }
              name {
                text
                html
              }
              role {
                html
                text
              }
            }
            primary {
              testimonial_heading {
                html
                text
              }
              testimonial_label {
                html
                text
              }
            }
          }
          ... on PrismicCompetitorLandingPageV3DataBodyEncordProducts {
            id
            slice_type
            primary {
              section_label {
                html
                text
              }
            }
            items {
              cta_link {
                url
              }
              product_description {
                text
              }
              product_heading {
                text
                html
              }
              product_icon {
                dimensions {
                  height
                  width
                }
                alt
                url
              }
              product_image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
              product_sub_heading {
                html
                text
              }
              product_video {
                url
              }
            }
          }
          ... on PrismicCompetitorLandingPageV3DataBodyEncordOtherFeatures {
            id
            slice_type
            primary {
              section_heading {
                html
                text
              }
              cta_text {
                html
                text
              }
              label {
                text
              }
              cta_link {
                url
              }
            }
            items {
              card_description {
                html
                text
              }
              card_heading {
                html
                text
              }
              card_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              card_label {
                html
                text
              }
            }
          }
          ... on PrismicCompetitorLandingPageV3DataBodyAnnotationToolsSection {
            id
            slice_type
            primary {
              cta_link {
                url
              }
              cta_text {
                html
                text
              }
              label {
                html
                text
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
            }
            items {
              dark_mode_image {
                alt
                url
              }
              label {
                html
                text
              }
              light_mode_image {
                alt
                url
              }
            }
          }
          ... on PrismicCompetitorLandingPageV3DataBodyFeatureTable {
            id
            slice_type
            primary {
              competitor_logo {
                alt
                url
              }
              heading {
                html
                text
              }
              encord_logo {
                alt
                url
              }
              cta_text {
                text
              }
              competitor_name {
                html
                text
              }
            }
            items {
              competitor_score {
                html
                text
              }
              encord_score {
                html
                text
              }
              feature_name {
                html
                text
              }
            }
          }
          ... on PrismicCompetitorLandingPageV3DataBodyContactSalesQuotes {
            id
            items {
              author_name {
                html
                text
              }
              label {
                html
                text
              }
              review_description {
                html
                text
              }
              review_heading {
                html
                text
              }
            }
            slice_type
          }
          ... on PrismicCompetitorLandingPageV3DataBodySectionWithImage {
            id
            primary {
              footer_image {
                url
              }
              footer_label {
                text
              }
              footer_sub_description {
                text
              }
              section_description {
                text
              }
              section_heading {
                text
                html
              }
              section_image {
                url
                alt
                dimensions {
                  height
                  width
                }
              }
            }
            slice_type
          }
          ... on PrismicCompetitorLandingPageV3DataBodyPageSection {
            id
            slice_type
            primary {
              label_icon {
                url
              }
              section_cta_link {
                url
              }
              section_cta_text {
                html
                text
              }
              section_description {
                html
                text
              }
              section_heading {
                html
                text
              }
              section_image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
              section_label {
                text
                html
              }
              section_video {
                url
              }
              type
            }
            items {
              section_card_cta_link {
                url
              }
              section_card_cta_text {
                text
              }
              section_card_description {
                text
              }
              section_card_heading {
                text
              }
              section_card_image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_card_image_dark {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
              section_card_label {
                html
                text
              }
            }
          }
        }
      }
      uid
    }
    allPrismicTrustedBrandsLogos(
      filter: {
        uid: { in: ["itk-comp-page-logos-light", "itk-comp-page-logos-dark"] }
      }
    ) {
      nodes {
        uid
        data {
          body {
            ... on PrismicTrustedBrandsLogosDataBodyTrustedBrandsList {
              id
              slice_type
              items {
                brand_image {
                  alt
                  url
                }
              }
              primary {
                label {
                  html
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
  const _data = data?.prismicCompetitorLandingPageV3?.data;
  return (
    <SEO
      title={_data?.meta_heading?.text}
      description={_data?.meta_description?.text}
    />
  );
};

export default CompetitiorsLP;
